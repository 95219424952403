.button {
  background-color: $green;
  border: 0;
  color: $white;
  cursor: pointer;
  font-size: .75rem;
  font-weight: lighter;
  line-height: 23px;
  outline: 0;
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
  transition: .25s all;

  &:hover {
    background-color: darken($green, 10%);
  }

  &--outline {
    @include button-color-scheme(darken($grey, 40%), true);
  }

  &--buy {
    @include button-color-scheme($green);
  }

  &--details {
    @include button-color-scheme(darken($grey, 40%), true);
  }

  &--big {
    align-items: center;
    display: flex;
    font-size: 1.125rem;
    justify-content: space-around;
    line-height: normal;
    min-height: 45px;
    width: 100%;

    i {
      font-size: 2rem;
    }

    small {
      display: block;
      font-size: .75rem;
      text-transform: lowercase;
    }
  }
}

a.button {
  display: inline-block;

  &:hover,
  &:focus {
    color: $white;
  }
}

.btn-remove {
  color: $text-primary;
  font-size: 1rem;
  padding: 2px;
  transition: .25s color;

  &:hover {
    color: $red;
  }
}

.input-text + button {
  background: none;
  border: 0;
  font-weight: normal;

  &:hover {
    background: none;
  }
}

.back-link,
.back-store {
  color: $text-grey;
  float: left;
  font-weight: bold;
  line-height: 40px;
  text-transform: uppercase;
}

.buttons-set .button,
.title-buttons__container {
  float: right;
}
