// General store tables
.table,
.data-table {
  border-collapse: collapse;
  display: table;
  margin-bottom: 15px;
  width: 100%;

  colgroup {
    display: none;
  }

  td,
  th {
    border: 1px solid $grey;
    border-collapse: collapse;
    font-weight: inherit;
    padding: 15px;
    text-align: center;
    vertical-align: middle;

    @media #{$smartphone} {
      padding: 5px;
    }
  }

  thead {
    background-color: $grey;
  }

  tbody {
    font-size: .875rem;

    .separator {
      display: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  tfoot {
    td {
      &:first-of-type {
        text-align: left;
      }

      &:last-of-type {
        text-align: right;
      }
    }
  }

  .columns {
    align-items: center;
    margin-left: 0;
    margin-right: 0;

    @media #{$tablet} {
      display: table;
      width: 100%;
    }

    &__column {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
