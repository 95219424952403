footer {
  background-color: $grey;
  color: $text-primary;
  padding-bottom: 60px;
  padding-top: 60px;

  h2 {
    color: $red;
    font-size: 1.125rem;
    font-weight: bold;
  }

  p {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  a:hover {
    color: $red;
  }
}

.copyright {
  font-size: .625rem;
  margin-bottom: 0;
  padding: 15px 0 0;

  &__logo {
    img {
      height: 50px;
    }
  }
}

@media #{$desktop} {
  .copyright {
    &__text,
    &__logo {
      line-height: 50px;
    }

    &__logo {
      flex: initial;
      text-align: right;
    }
  }
}

@media #{$mobile} {
  footer,
  .copyright {
    text-align: center;
  }
}
