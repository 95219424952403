.category-products .owl-carousel,
.manufacturers .owl-carousel {
  position: relative;

  .owl-buttons {
    background-color: $grey;
    border-radius: 35px;
    padding: 4px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -65px;

    .owl-next,
    .owl-prev {
      background-color: $white;
      border-radius: 50%;
      color: darken($grey, 30%);
      display: inline-block;
      font-size: .75rem;
      line-height: 34px;
      transition: color .25s;
      width: 34px;

      &:hover {
        color: darken($grey, 50%);
      }
    }

    .owl-prev {
      margin-right: 2px;
    }

    .owl-next {
      margin-left: 2px;
    }
  }

  .item {
    margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.magestore-bannerslider {
  &.container {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .flexslider {
    border: 0;
    box-shadow: none;
    margin-bottom: 15px;

    ol.flex-control-nav {
      bottom: 15px;

      li a {
        background-color: $white;
        height: 12px;
        width: 12px;

        &.flex-active {
          background-color: $red;
        }
      }
    }

    ul.flex-direction-nav li a {
      opacity: .8;

      &.flex-prev {
        left: 30px;
      }

      &.flex-next {
        right: 30px;
      }
    }
  }
}

.category-products--secondary .owl-carousel .owl-buttons {
  background: none;
  right: 5px;
  top: -60px;
}

@media #{$tablet} {
  .magestore-bannerslider .flexslider ul.flex-direction-nav li a {
    &.flex-next {
      right: 15px;
    }

    &.flex-prev {
      left: 15px;
    }
  }
}

@media #{$smartphone} {
  .magestore-bannerslider .flexslider {
    display: none;
  }
}
