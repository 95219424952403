.link {
  color: $green;

  &:hover {
    text-decoration: underline;
  }
}

.page-title {
  background-color: $white;
  border-bottom: 1px solid $red;
  color: $red;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;

  h1,
  h2 {
    display: inline-block;
    font-size: 1.25rem;
    margin-bottom: 0;

    b {
      position: relative;

      &::after {
        border-bottom: 1px solid $red;
        border-top: 1px solid $red;
        content: '';
        left: 0;
        position: absolute;
        top: calc(100% + 13px);
        width: 100%;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin-top: 0;
}
