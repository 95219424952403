.top-cart {
  background: $white;
  box-shadow: 0 0 5px $grey;
  color: $red;
  display: none;
  padding: 15px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 100%;
  transition: .5s all;
  white-space: nowrap;
  z-index: 9;

  @media #{$smartphone} {
    width: 100%;
  }

  &.fixed {
    display: block;
    max-height: 100%;
    overflow-y: auto;
    position: fixed;
    right: $container-padding;
    top: -100%;
    transition: 1s all;

    &.show {
      top: 0;
    }

    @media #{$smartphone} {
      height: 100%;
      right: 0;
    }
  }

  &__content {
    color: $text-primary;

    p.empty {
      font-size: 14px;
      font-weight: 300;
    }

    ol {
      list-style: none;

      li {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:not(:last-of-type) {
          border-bottom: 1px solid $grey;
        }

        > div {
          flex: 1;
        }

        .product-image {
          display: inline-block;
          margin-right: 15px;
          width: 70px;
        }

        .product-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-right: 30px;
          position: relative;
          text-align: left;

          .product-name {
            max-width: 130px;
            word-wrap: break-word;

            a {
              float: none;
              font-size: 14px;
              margin: 0;
              text-transform: capitalize;
            }
          }

          .price {
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
          }

          .qty {
            font-size: .75rem;
            text-align: left;
            text-transform: capitalize;
          }

          .remove-i {
            color: $red;
            position: absolute;
            right: 0;
            top: calc(50% - 9px);
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;

      .button {
        padding-left: 15px;
        padding-right: 15px;

        &:first-of-type {
          margin-right: 5px;
        }
      }
    }
  }

  .top-subtotal {
    border-bottom: 1px solid $grey;
    border-top: 1px solid $grey;
    clear: both;
    font-size: .9rem;
    font-weight: bold;
    line-height: 20px;
    margin: 15px 0;
    padding: 15px 0;
    text-transform: capitalize;

    span {
      color: $red;
      float: right;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .actions {
    margin-top: 15px;
    text-align: center;
  }
}

.top-cart-overlay {
  background: transparentize($white, .1);
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 10;

  &.active {
    display: table;
  }

  &__message {
    color: $red;
  }

  > div {
    display: table-cell;
    vertical-align: middle;
  }
}
