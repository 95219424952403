.badge {
  background-color: $white;
  display: flex;
  font-size: .75rem;
  justify-content: center;
  padding: 5px;
  text-align: center;

  &--sales {
    background-color: $green;
    border: 1px solid $green;
    color: $white;
  }

  &--new {
    border: 1px solid $blue;
    color: $blue;
  }
}
