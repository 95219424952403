.newsletter {
  background-color: $red;
  color: $white;
  margin-bottom: 0;
  padding-bottom: 22px;
  padding-top: 22px;

  @media #{$mobile} {
    text-align: center;

    &__col-right {
      margin-bottom: 0;
    }
  }

  .newsletter__col-left {
    text-align: right;

    @media #{$mobile} {
      text-align: center;
    }

    span {
      display: block;
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-bottom: 0;
      margin-top: 0;

      &:last-of-type {
        font-size: 1.675rem;
        font-weight: bold;
      }
    }
  }

  .newsletter__col-right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    form {
      display: inline-block;
      font-size: .75rem;
      line-height: initial;
      position: relative;
      width: 100%;

      button,
      input {
        border: none;
        border-radius: 0;
        text-transform: uppercase;
      }

      input {
        background: $white;
        height: 45px;
        padding: 0 15px;
      }

      button {
        background-color: $red;
        line-height: 30px;
        margin-top: 7px;
        padding: 0;
        position: absolute;
        right: 7px;
        top: 0;
        width: 80px;

        &:hover {
          background-color: darken($red, 10%);
        }
      }

      .validation-advice {
        color: $white;
        margin-top: 5px;
      }
    }
  }
}
