%message-container {
  box-shadow: $outter-box-shadow;
  font-size: .75rem;
  padding: 15px;
  text-transform: uppercase;
}

.messages {
  @extend %message-container;

  margin-bottom: 15px;
  margin-top: 15px;
}

.global-site-notice {
  @extend %message-container;

  background-color: $blue;
  bottom: 0;
  color: $white;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}

.welcome-msg {
  @extend %message-container;

  margin-bottom: 15px;
}

%message-status {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: .875rem;
  font-size: .875rem;
  padding-left: 1.125rem;
  padding-top: 5px;
}

.onestepcheckout-success-msg,
.success-msg {
  @extend %message-status;

  background-image: url('#{$images}ok.svg');
  color: $green;
}

.onestepcheckout-warning-msg,
.warn-msg {
  @extend %message-status;

  background-image: url('#{$images}caution.svg');
  color: $yellow;
}

.error-msg,
.onestepcheckout-error-msg {
  @extend %message-status;

  background-image: url('#{$images}cancel.svg');
  color: $red;
}
