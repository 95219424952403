.navigation-bar {
  background-color: $white;
  border-bottom: 6px solid $grey;
  margin-bottom: 15px;

  &__mobile-header {
    color: $red;
    display: none;
    justify-content: space-between;
    line-height: 40px;
    text-transform: uppercase;

    .hamburger-icon {
      display: flex;
      flex-direction: column;
      padding-top: 13px;

      span {
        border-bottom: 3px solid $red;
        display: inline-block;
        height: 0;
        width: 20px;

        &:not(:first-of-type) {
          margin-top: 3px;
        }
      }
    }

    @media #{$smartphone} {
      display: flex;
    }
  }

  .checkbox {
    display: none;

    @media #{$smartphone} {
      &:checked + div {
        display: none;
      }
    }
  }

  #nav {
    display: table;
    text-align: center;
    width: 100%;

    li.level0 {
      color: $red;
      display: table-cell;
      position: relative;

      &:last-of-type {
        color: $black;
        font-weight: bold;
      }

      &::after {
        border-bottom: 6px solid $red;
        content: '';
        left: 50%;
        position: absolute;
        top: 100%;
        transition: .3s all;
        width: 0;
      }

      &:hover {
        &::after {
          left: 0;
          width: 100%;
        }

        > ul {
          display: flex;
        }
      }

      a {
        display: block;
        padding-bottom: 10px;
        padding-top: 10px;
        text-transform: uppercase;
      }

      > ul {
        background-color: $white;
        box-shadow: $outter-box-shadow;
        display: none;
        flex-wrap: wrap;
        left: 0;
        padding-bottom: 15px;
        padding-top: 15px;
        position: absolute;
        text-align: left;
        top: calc(100% + 6px);
        width: auto;
        z-index: 2;

        li {
          color: $black;
          flex-basis: calc(50% - 30px);
          font-size: .8125rem;
          padding-left: 15px;
          padding-right: 15px;
          transition: .25s all;
          white-space: nowrap;

          &:hover {
            color: $red;
          }

          ul {
            display: flex;
            flex-wrap: wrap;
          }

          a {
            text-transform: lowercase;
          }
        }
      }
    }

    @media #{$smartphone} {
      border-top: 2px solid $grey;

      li.level0 {
        display: block;

        > ul {
          box-shadow: none;
          display: block;
          padding: 0;
          position: relative;
          text-align: center;
        }
      }
    }
  }
}
