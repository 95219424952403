form {
  input:not([type='checkbox']):not([type='radio']),
  select,
  textarea {
    background-color: $white;
    border: 1px solid $grey;
    border-radius: $form-radius;
    color: $text-primary;
    font-size: .8125rem;
    line-height: 36px;
    outline: none;
    padding: 0 12px;
    transition: border .5s;
    width: 100%;

    &:active,
    &:focus {
      border-color: $blue;
    }

    &:invalid {
      border-color: $red;
    }

    &[readonly],
    &[disabled] {
      background-color: $grey;
    }

    &.input--flat {
      border: 1px solid transparent;
      border-radius: $form-radius;
      min-height: 40px;
      text-align: center;

      &:focus {
        border-color: $blue;
      }
    }
  }

  input {
    &[type='checkbox'],
    &[type='radio'] {
      display: inline-block;
      margin-right: 5px;

      + label {
        margin: 0;
      }
    }
  }

  label {
    display: inline-block;
    font-size: .875rem;
    font-weight: normal;
  }

  select {
    padding-right: 15px;
  }

  .required em,
  p.required {
    color: $red;
    font-size: .75rem;
  }

  .required em {
    float: right;
  }

  .wide,
  .one-field {
    margin: 0 0 15px;
    width: 100%;
  }

  .fields {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .field {
      flex-basis: calc(50% - 5px);
    }
  }

  // OneStepCheckout based forms
  .fieldset {
    margin-bottom: 30px;

    ul {
      margin-bottom: 0;
    }

    .legend {
      font-size: 1.125rem;
      font-weight: bold;
      margin: 0 0 15px;
    }
  }

  .form-list {
    display: flex;
    flex-direction: column;

    li {
      margin: 0 0 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .customer-dob {
    display: flex;
    justify-content: space-between;

    > div {
      flex-basis: calc(33% - 5px);
    }
  }

  // Validation
  .validation-advice {
    color: $red;
    font-size: .75rem;
  }

  .validation-failed:not(button),
  .validation-passed:not(button) {
    background-position: calc(100% - 5px);
    background-repeat: no-repeat;
    background-size: 15px;
  }

  .validation-passed:not(button) {
    background-image: url('#{$images}ok.svg');
    border-color: $green;
  }

  .validation-failed:not(button) {
    background-image: url('#{$images}cancel.svg');
    border-color: $red;
  }
}

// General elements
select {
  appearance: none;
  background-color: darken($white, .1);
  background-image: url('#{$images}/select_arrows.svg');
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  background-size: 6px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 15px;
}

.button-set,
.buttons-set {
  p {
    font-size: .75rem;
  }
}
