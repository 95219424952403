// Loadings
.loading-container {
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;

  // Loading circle
  .loading-container__circle {
    animation: spin 1s infinite linear;
    border: 3px solid transparent;
    border-bottom-color: $blue;
    border-radius: 50%;
    display: inline-block;
    height: 75px;
    margin: 10px;
    transition: color 1s, border-radius 3s;
    width: 75px;

    &--error {
      animation: none;
      border-bottom-color: $red;
      border-radius: 0;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
