.category-products {
  position: relative;
  text-align: center;

  &--primary .products-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      flex-basis: calc(100% / #{$smartphone-columns} - 5px);

      @media #{$desktop} {
        flex-basis: calc(100% / #{$desktop-columns} - 15px);
      }

      @media #{$tablet} {
        flex-basis: calc(100% / #{$tablet-columns} - 15px);
      }
    }
  }

  &--secondary {
    background-color: $grey;
    padding-bottom: 30px;

    .category-products__title {
      margin-bottom: 30px;
      padding-top: 30px;
    }

    .products-grid .item {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &--carousel .products-grid {
    @media #{$desktop} {
      .owl-carousel .owl-buttons {
        top: -60px;
      }
    }

    .item {
      flex-basis: initial;
    }
  }

  @media #{$mobile} {
    .products-grid .owl-buttons {
      background: none;
      padding: 0;
      position: static;

      .owl-next,
      .owl-prev {
        background: none;
        font-size: 1.25rem;
        position: absolute;
        top: 45%;
      }

      .owl-prev {
        left: -15px;
      }

      .owl-next {
        right: -15px;
      }
    }
  }
}

.products-grid {
  .item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
    text-align: left;
    transition: .5s box-shadow;

    &:hover {
      .actions {
        opacity: 1;
      }
    }

    > * {
      flex: 1;
    }

    .badge {
      $size: 40px;

      border-radius: 50%;
      flex-direction: column;
      height: $size;
      padding: 0;
      position: absolute;
      top: 15px;
      width: $size;

      &--new {
        left: 15px;
      }

      &--sales {
        right: 15px;
      }
    }

    .box-image {
      border: 1px solid darken($grey, 15%);
      margin-bottom: 15px;
    }

    .product-name {
      font-size: .875rem;
      font-weight: normal;
      text-transform: uppercase;
    }

    .rating-box {
      width: 60px;

      &,
      .rating {
        background-size: 12px;
        height: 12px;
      }

      .rating {
        background-position: 0 -49px;
      }
    }

    .price-box {
      .price-label {
        text-transform: lowercase;
      }

      .regular-price,
      .special-price {
        font-size: 1.125rem;

        .price {
          color: $red;
        }
      }

      .old-price {
        font-size: .75rem;
        text-decoration: line-through;
      }

      small {
        display: none;
      }
    }

    .installments,
    &__bank-slip {
      font-size: .75rem;
    }

    .actions {
      align-items: center;
      border-top: 1px solid darken($grey, 15%);
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      padding-top: 8px;
      transition: .5s opacity;

      @media #{$desktop} {
        opacity: 0;
      }

      .button {
        display: inline-block;
        font-size: .75rem;
        line-height: 23px;
        padding: 0;
        padding-left: 2px;
        padding-right: 2px;
        width: calc(50% - 5px);
      }
    }

    &__more-category {
      border-top: 1px solid $grey;
      color: $green;
      display: none;
      font-size: .75rem;
      font-weight: 400;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}
