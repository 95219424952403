.header {
  background-color: $red;
  color: $white;

  &-store-info,
  &-main {
    display: flex;
    justify-content: space-between;
  }

  &-store-info {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: .75rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid darken($red, 5%);

    &__external-links,
    &__links,
    &__contact {
      margin-bottom: 5px;

      @media #{$desktop} {
        margin-bottom: 0;
      }
    }

    &__external-links {
      text-transform: uppercase;

      ul {
        display: flex;

        @media #{$tablet} {
          flex-direction: column;
        }

        li {
          a {
            display: block;
            margin-bottom: 5px;

            &:hover {
              text-decoration: underline;
            }
          }

          @media #{$desktop}, #{$smartphone} {
            a {
              margin-bottom: 0;
              padding-right: 10px;
            }

            &:not(:first-of-type) a {
              padding-left: 10px;
              position: relative;

              &::before {
                content: '|';
                left: 0;
                position: absolute;
                top: -1px;
              }
            }
          }
        }
      }
    }

    &__contact {
      span {
        display: inline-block;

        &:first-of-type {
          margin-right: 15px;
          padding-right: 15px;
          position: relative;

          &::after {
            content: '|';
            position: absolute;
            right: 0;
            top: -1px;
          }
        }

        p {
          display: inline-block;
        }
      }
    }

    &__links {
      display: flex;

      @media #{$tablet} {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    &__block {
      display: inline-block;
      font-size: .75rem;
      margin-left: 30px;
      text-transform: uppercase;

      @media #{$tablet} {
        margin-bottom: 5px;
        margin-left: 0;
      }

      .fa {
        font-size: 1rem;
        margin-right: 5px;
      }

      a {
        display: inline-block;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media #{$smartphone} {
      flex-wrap: wrap;
      text-align: center;

      > div {
        width: 100%;
      }

      &__external-links {
        ul {
          justify-content: center;
        }
      }

      &__contact {
        @media #{$tablet} {
          flex-direction: column;
          justify-content: center;
        }

        span:first-of-type {
          padding-right: 0;
          margin-right: 0;

          &::after {
            content: none;
          }
        }

        span {
          padding: 0 5px;
        }
      }

      &__links {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &__block {
        margin-left: 0;
      }
    }
  }

  &-main {
    padding-bottom: 15px;
    padding-top: 15px;

    @media #{$smartphone} {
      flex-wrap: wrap;

      > div {
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 15px;
      }
    }

    &__search {
      border-radius: 0;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      transition: .5s padding;

      @media #{$desktop} {
        padding-left: 100px;
        padding-right: 100px;
      }

      @media #{$tablet} {
        padding-left: 15px;
        padding-right: 15px;
      }

      .input-box {
        position: relative;
      }

      .search-form {
        background-color: $white;
        display: flex;

        label {
          display: none;
        }

        .input-text {
          background: none;
          border: none;
          color: $text-primary;
          font-size: .75rem;
          padding-left: 15px;
          text-transform: uppercase;
        }

        .search-button {
          color: $red;
          font-size: 17px;
          outline: none;
          padding: 0;
          transition: .25s color;
          width: 45px;
          height: 45px;
        }
      }

      .search-loader {
        color: $red;
        right: 45px;
        top: 13px;
      }
    }

    &__cart {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;

      &:hover .top-cart {
        display: block;
      }

      .cart {
        &__icon {
          border: 1px solid $white;
          display: flex;
          font-size: .625rem;
          padding: 10px 5px;
          position: relative;
          text-transform: uppercase;
          z-index: 2;

          &::before {
            $size: 12px;

            background-color: $red;
            border: 1px solid $white;
            border-left: none;
            border-top: none;
            content: '';
            transform: rotateZ(45deg);
            height: $size;
            left: calc(50% - #{$size/2});
            position: absolute;
            top: calc(100% - #{$size/2});
            width: $size;
            z-index: 1;
          }

          .fa {
            font-size: 30px;
            margin-right: 15px;
          }

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
              display: block;
            }

            .title {
              font-size: .75rem;
            }
          }
        }
      }
    }
  }

  .attendance {
    position: relative;

    @media #{$smartphone} {
      position: static;
    }

    &:hover .attendance__popup {
      transform: scale(1);
    }

    &__popup {
      background-color: $white;
      border-radius: 8px;
      box-shadow: $outter-box-shadow;
      color: $text-primary;
      font-size: .8125rem;
      line-height: normal;
      padding: 20px;
      position: absolute;
      right: 0;
      text-transform: none;
      top: calc(100% + 10px);
      transform: scale(0);
      transform-origin: top;
      transition: .25s all;
      z-index: 5;

      &::before {
        height: 10px;
        width: 100%;
        content: '';
        bottom: 100%;
        position: absolute;
      }

      &::after {
        border-bottom: 10px solid $white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: '';
        height: 0;
        position: absolute;
        right: 15px;
        top: -10px;
        width: 0;
      }

      .list {
        &__item {
          display: flex;
          white-space: nowrap;

          &:not(:first-of-type) {
            margin-top: 15px;
          }

          > div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:first-of-type {
              margin-right: 10px;
            }

            .fa {
              font-size: 22px;
            }

            a {
              color: $red;
              text-decoration: underline;
            }
          }
        }
      }

      .phone {
        font-size: 1rem;
      }
    }
  }
}
