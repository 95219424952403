// Simple column layout

.columns {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;

  @media #{$mobile} {
    flex-wrap: wrap;
  }

  &__column {
    padding-left: 15px;
    padding-right: 15px;

    @media #{$desktop} {
      flex: 1;
    }

    @media #{$mobile} {
      margin-bottom: 15px;
    }

    @media #{$tablet} {
      flex-basis: 50%;
    }

    @media #{$smartphone} {
      flex-basis: 100%;
    }

    &--bordered {
      border-color: $grey;
      border-right-style: solid;
      border-width: thin;

      @media #{$smartphone} {
        border-bottom-style: solid;
        border-right-style: hidden;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }
  }

  &--has-sidebar > &__column {
    @media #{$tablet-landscape}, #{$desktop} {
      &:first-of-type {
        flex: initial;
        flex-basis: percentage(1/4);
      }

      &:last-of-type {
        flex-basis: percentage(3/4);
      }
    }

    @media #{$tablet-portrait} {
      flex-basis: 100%;
    }
  }
}
