// Base HTML styles

* {
  box-sizing: border-box;
}

html {
  background-color: $white;
  font-size: 16px;

  @media #{$smartphone} {
    font-size: 14px;
  }
}

body {
  color: $text-primary;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

section {
  margin-bottom: 30px;
}

a {
  &,
  &:hover,
  &:focus {
    color: inherit;
    outline: 0;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

ol,
ul,
p,
dl {
  margin-bottom: 0;
  margin-top: 0;
}

dd {
  margin-left: 0;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

b {
  font-weight: 700;
}

fieldset {
  border: none;
  padding: 0;
}

.no-display {
  display: none;
}

.clearfix {
  clear: both;
}

.container {
  padding-left: $container-padding;
  padding-right: $container-padding;
  width: auto;

  @media #{$mobile} {
    padding-left: 5%;
    padding-right: 5%;
  }
}
