// Button color scheme
@mixin button-color-scheme($color, $outline: false) {
  border: 1px solid $color;

  @if $outline {
    background-color: $white;
    box-shadow: inset 0 0 $color;
    color: $color;

    &:hover {
      background-color: $white;
      box-shadow: inset 0 25px $color;
      color: $white;
    }
  }

  @else {
    background-color: $color;
    color: $white;

    &:hover {
      $darker-color: darken($color, 10%);

      background-color: $darker-color;
      border-color: $darker-color;
    }
  }
}
